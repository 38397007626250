import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { GameType } from "../models/game-types";
import { GameConfigContext } from "src/providers/game-provider";

interface GamePreviewCardProps {
  gameInfo: GameType
}


/**
 * Preview card showing a single game type.
 */
export const GamePreviewCard: React.FC<GamePreviewCardProps> = (props: GamePreviewCardProps) => {

  // Access the game configuration and setter functions from the context
  const { methods } = useContext(GameConfigContext)!;
  const { configureGame } = methods;

  return (
    <div className="
    bg-[#1d263e] flex flex-col justify-center items-center rounded-3xl text-center
      h-screen1/2 w-screen2/3 px-6 py-3  gap-5
      border-amber-700 border-2
    "
    onClick={() => configureGame(props.gameInfo)}
    >
      <div className="
        p-6 bg-[#1d263e] rounded-xl
        shadow-2xl
      ">
        <FontAwesomeIcon icon={props.gameInfo.icon} className="text-6xl md:text-8xl text-slate-300"/>
      </div>
      <span className="
          text-3xl  text-slate-300
      ">
        {props.gameInfo.name}
      </span>
      <span className="
          text-sm text-wrap text-slate-400
      ">
        {props.gameInfo.description}
      </span>
    </div>
  );
};
