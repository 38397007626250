import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "../components/header";
import React, { useContext, useState } from "react";
import { GameConfigContext } from "src/providers/game-provider";


export const GameView: React.FC = () => {
  // Access the game configuration and setter functions from the context
  const { gameConfig } = useContext(GameConfigContext)!;
  const { title, prompts } = gameConfig;
  // Local state values
  const [promptIndex, setPromptIndex] = useState<number>(0);

  const handlePromptChange = (change: number) => {
    setPromptIndex(promptIndex + change);
  };

  return (
    <div className="h-dvh w-dvw bg-gray-900 flex flex-col justify-start items-center">
      <Header backNavLocation="/" title={title}/>
      <div className="
        flex flex-col justify-center items-center gap-y-10
        h-2/3
      ">
        <span className="
          text-amber-700 font-bold text-center select-none
          text-5xl
        ">
          {prompts[promptIndex].Title}
        </span>
        <span className="
          text-white text-center select-none
          text-xl
        ">
          {prompts[promptIndex].Task}
        </span>
        <div
          className="flex flex-row items-center justify-evenly"
        >
          {
            promptIndex > 0 &&
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="ml-3 px-4 py-3 bg-amber-700 text-white rounded font-bold text-2xl"
              onClick={() => handlePromptChange(-1)}
            />
          }
          {
            promptIndex < prompts.length &&
            <FontAwesomeIcon
              icon={faArrowRight}
              className="ml-3 px-4 py-3 bg-amber-700 text-white rounded font-bold text-2xl"
              onClick={() => handlePromptChange(+1)}
            />
          }
        </div>
      </div>
    </div>
  );
};
