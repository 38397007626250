import { Prompt } from "@models/prompt";
import { GameConfig, GameConfigContextType } from "../models/game-config";
import React, { createContext, useState } from "react";
import { GameType } from "@models/game-types";
import { buildBevved } from "../services/prompt-service";



// Create a context for game configuration
export const GameConfigContext = createContext<GameConfigContextType | null>(null);


export const GameConfigProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
  const [gameConfig, setGameConfig] = useState<GameConfig>({title: "test game", nsfw: false, players: [], prompts: [{
    Type: "challenge",
    Title: "Test Question",
    NSFW: false,
    Task: "This is a test Question it does things"
  }]});

  const setTitle = (value: string) => {
    setGameConfig(prevConfig => ({ ...prevConfig, title: value }));
  };

  /**
   * Sets the nsfw filter
   * @param value `false` if nsfw questions should be excluded
   */
  const setNsfw = (value: boolean) => {
    setGameConfig(prevConfig => ({ ...prevConfig, nsfw: value }));
  };

  /**
   * Sets the players
   * @param value The list of players
   */
  const setPlayers = (value: string[]) => {
    setGameConfig(prevConfig => ({ ...prevConfig, players: value }));
  };

  /**
   * Sets the players
   * @param value The list of players
   */
  const setPrompts = (value: Prompt[]) => {
    setGameConfig(prevConfig => ({ ...prevConfig, prompts: value }));
  };

  const configureGame = async (gameType: GameType) => {
    setTitle(gameType.name);
    console.log(gameConfig.players);
    const prompts = await buildBevved(gameConfig.players); // Access players from the current state
    setPrompts(prompts);
  };

  const gameConfigContext: GameConfigContextType = {
    gameConfig,
    setters: {
      setTitle,
      setNsfw,
      setPlayers,
      setPrompts,
    },
    methods: {
      configureGame
    }
  };

  return (
    <GameConfigContext.Provider value={gameConfigContext}>
      {children}
    </GameConfigContext.Provider>
  );

};
