import React from "react";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";

/**
 * The home screen of the application
 */
export default class WelcomeView extends React.Component{

  override render(): React.ReactNode {
    return (
      <div className="h-dvh w-screen bg-gray-900 flex flex-col justify-center items-center">
        <div className="h-screen/2 flex justify-center items-center">
          <img src={logo} alt="logo" className="w-1/2"/>
        </div>
        <div className="h-screen/2 flex flex-col justify-start items-center gap-14">
          <span className="
            bg-gradient-to-br from-amber-700 to-amber-400 text-transparent bg-clip-text inline-block
            text-6xl text-center font-bold
            md:text-3xl
            xl:text-4xl
          ">
            bevved
          </span>
          <Link to="/players" style={{textDecoration: "none"}}>
            <button className="
            bg-[#1d263e] text-neutral-300 font-bold rounded-xl border-amber-700 border-2
            text-3xl px-10 py-2
            ">
              play
            </button>
          </Link>
          <Link to="/settings" style={{textDecoration: "none"}}>
            <button className="
              bg-[#1d263e] text-neutral-300 font-bold rounded-xl border-amber-700 border-2
              text-3xl px-10 py-2
              ">
                settings
            </button>
          </Link>
        </div>
      </div>
    );
  }
}
