import React, { ReactElement } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { GamePreviewCard } from "../components/game-preview-card";
import { GameModes } from "../models/game-types";

/**
 * View allowing user to choose a game mode
 */
export const GameSelectionView: React.FC = () => {


  const modeCards: Array<ReactElement> = [];
  for (const mode of GameModes){
    modeCards.push(
      <SplideSlide style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
        padding: 20
      }}>
        <Link to="/game" style={{textDecoration: "none"}}>
          <GamePreviewCard gameInfo={mode} />
        </Link>
      </SplideSlide>
    );
  }


  return (
    <div className="h-dvh w-screen bg-gray-900 flex flex-col justify-start items-center">
      <div className="
        w-screen flex flex-row justify-between items-center
        h-16 py-1 px-6
      ">
        <Link to="/" style={{textDecoration: "none"}}>
          <FontAwesomeIcon icon={faArrowLeft} className="text-4xl md:text-2xl text-neutral-400"/>
        </Link>
        <span className="
          text-amber-600 font-semibold
          text-2xl
        ">
          choose game
        </span>
        <div></div>
      </div>

      <div className="h-screen flex flex-col justify-center items-center">
        <Splide
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          aria-label="Game Modes"
          options={{
            type: "loop",
            arrows: false,
            perPage: 1,
            wheel: true,
            drag: "free",
            snap: true,
            width: "100dvw",
            pagination: false,
            flickPower: 200,
          }}
        >
          {modeCards}
        </Splide>
      </div>
    </div>
  );
};
