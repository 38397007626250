import React from "react";
import { Route, HashRouter as Router, Routes } from "react-router-dom";
import WelcomeView from "./views/welcome-view";
import { SettingsView } from "./views/settings-view";
import { GameSelectionView } from "./views/game-selection-view";
import { GameConfigProvider } from "./providers/game-provider";
import { GameView } from "./views/game-view";
import { UserEntryView } from "./views/user-entry-view";

export default class App extends React.Component{

  override render(): React.ReactNode {
    return (
      <GameConfigProvider>
        <Router>
          <Routes>
            <Route path="/" Component={WelcomeView} />
            <Route path="/config" Component={GameSelectionView} />
            <Route path="/settings" Component={SettingsView} />
            <Route path="/players" Component={UserEntryView} />
            <Route path="/game" Component={GameView} />
          </Routes>
        </Router>
      </GameConfigProvider>
    );
  }
}
