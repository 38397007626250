import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faTrophy, faCheckToSlot, faSkullCrossbones, faDice, faBeerMugEmpty } from "@fortawesome/free-solid-svg-icons";

/**
 * Model representing a type of game.
 */
export type GameType = {
  /**
   * The name of the game.
   */
  name: string,
  /**
   * A short description of the game.
   */
  description: string,
  /**
   * A FontAwesome icon which represents the game
   */
  icon: IconProp
}

/**
 * A list of all game modes in the game
 */
export const GameModes: GameType[] = [
  {
    name: "bevved" ,
    description: "A variety of all game modes mixed into one",
    icon: faBeerMugEmpty
  },
  {
    name: "Never Have I Ever" ,
    description: "The ultimate icebreaker where players confess to wild and hilarious experiences",
    icon: faSkullCrossbones
  },
  {
    name: "Challenges" ,
    description: "Outrageous dares where players face off in hilarious contests",
    icon: faTrophy
  },
  {
    name: "Vote",
    description: "Get ready to play judge and jury",
    icon:faCheckToSlot
  },
  {
    name: "Games",
    description: "A library of other games which can be played",
    icon: faDice
  }
];
