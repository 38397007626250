import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { GameConfigContext } from "src/providers/game-provider";
import { Link } from "react-router-dom";

export const UserEntryView: React.FC = () => {
  const { gameConfig, setters } = useContext(GameConfigContext)!;
  const { setPlayers } = setters;
  const { players } = gameConfig;
  const [users, setUsers] = useState<string[]>(players);
  const [inputValue, setInputValue] = useState<string>("");
  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);

  useEffect(() => {
    setIsDuplicate(users.includes(inputValue.trim()));
  }, [inputValue, users]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setIsDuplicate(users.includes(inputValue.trim()));
  };

  const handleAddUser = (e: FormEvent) => {
    e.preventDefault();
    const trimmedValue = inputValue.trim();
    setIsDuplicate(users.includes(inputValue.trim()));
    if (trimmedValue && !isDuplicate) {
      setUsers([...users, trimmedValue]);
      setInputValue("");
    }
    console.log(users);
  };

  const handleRemoveUser = (index: number) => {
    setUsers(users.filter((_, i) => i !== index));
  };

  const handleSubmit = () => {
    setPlayers(users);
  };

  return(
    <div className="h-dvh w-screen bg-gray-900 flex flex-col justify-center items-center">
      <div className="flex flex-wrap justify-center items-center">
        {users.map((user, index) => (
          <div
            key={index}
            className="flex items-center py-1 px-3 m-1 bg-gray-200 rounded-xl text-lg"
          >
            {user}
            <span
              className="ml-2 cursor-pointer text-red-600 text-2xl font-bold"
              onClick={() => handleRemoveUser(index)}
            >
              &times;
            </span>
          </div>
        ))}
      </div>
      <form className="flex items-center justify-center mb-4" onSubmit={handleAddUser}>
        <input
          type="text"
          placeholder="Enter Player"
          className={`appearance-none flex-1 p-2 border rounded ${isDuplicate ? "border-red-500 border-4" : "border-gray-300"}`}
          value={inputValue}
          onChange={handleInputChange}
        />
        <button type="submit" className="ml-2 px-4 py-2 bg-amber-700 text-white rounded font-bold text-2xl">
          +
        </button>
        {users.length > 0 &&
          <Link to="/config" style={{textDecoration: "none"}}>
            <FontAwesomeIcon
              icon={faArrowRight}
              className="ml-3 px-4 py-3 bg-amber-700 text-white rounded font-bold text-2xl"
              onClick={() => handleSubmit()}
            />
          </Link>
        }


      </form>

    </div>
  );
};
