import React, { ReactElement } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

/**
 * Props for the Header component.
 */
type HeaderProps = {
    /**
     * Whether the backwards navigation button should be shown
     */
    backNav: boolean,
    /**
     * Where should the navigate button redirect to.
     *
     * A string path as provided in app.tsx
     */
    backNavLocation: string
    /**
     * The title to be displayed on the header bar.
     */
    title: string
    /**
     * OPTIONAL - The component to display on the right hand side of the Header.
     */
    rightComponent: ReactElement
}

/**
 * Header bar component.
 */
export default class Header extends React.Component<HeaderProps>{
  /**
   * The default prop arguments.
   */
  static defaultProps: Partial<HeaderProps> = {
    backNav: true,
    rightComponent: <div></div>
  };

  override render(): React.ReactNode {
    const { backNav, backNavLocation, title, rightComponent } = this.props;

    return (
      <div className="
        w-screen flex flex-row justify-between items-center
        h-16 py-1 px-3
      ">
        {backNav && (
          <Link to={backNavLocation || "/"} style={{textDecoration: "none"}}>
            <FontAwesomeIcon icon={faArrowLeft} className="text-4xl md:text-2xl text-neutral-400"/>
          </Link>
        )}
        <span className="
          text-amber-600 font-semibold
          text-2xl
        ">
          {title}
        </span>
        {rightComponent}
      </div>
    );
  }
}
