import { Prompt } from "../models/prompt";
import { initializeApp } from "firebase/app";
import { getFirestore, getDocs, collection, connectFirestoreEmulator } from "firebase/firestore";
import { Analytics, getAnalytics } from "firebase/analytics";

/**
 * Service providing access to Firebase and its services
 */
export class FirebaseService {

  firebaseConfig = {
    apiKey: "AIzaSyBQAsIoQF5B3WrqJnww_nBMZARi_9kg5-A",
    authDomain: "bevved-e4bb7.firebaseapp.com",
    projectId: "bevved-e4bb7",
    storageBucket: "bevved-e4bb7.appspot.com",
    messagingSenderId: "1030964390917",
    appId: "1:1030964390917:web:db0f320f2f3cc92957406c",
    measurementId: "G-EWTDGN6F38"
  };

  app = initializeApp(this.firebaseConfig);
  db = getFirestore(this.app);
  analytics: Analytics | undefined;


  constructor() {
    if (process.env.execution_location == "local" && (process.env.NODE_ENV == "test" || process.env.NODE_ENV == "development")) {
      connectFirestoreEmulator(this.db, "localhost", 8080);
    } else if (process.env.NODE_ENV == "production") {
      this.analytics = getAnalytics(this.app);
    }
  }


  /**
   * Get all documents within a collection
   * @param collectionName
   * @returns
   */
  async getAllDocs<Type>(collectionName: string): Promise<Type[]> {
    const querySnapshot = await getDocs(collection(this.db, collectionName));
    const docs: Type[] = querySnapshot.docs.map(doc => doc.data() as Type);
    return docs;
  }

  /**
   * Get all Prompts.
   *
   * @returns A list of all Prompts.
   */
  async getPrompts(): Promise<Prompt[]> {
    return await this.getAllDocs<Prompt>("prompts");
  }


  /**
   * Get all Never Have I Ever Prompts.
   *
   * @returns A list of all NHIE Prompts.
   */
  async getNeverPrompts(): Promise<Prompt[]> {
    const prompts: Prompt[] = await this.getAllDocs<Prompt>("prompts");
    const filtered: Prompt[] = prompts.filter((prompt) => prompt.Type == "NHIE");
    return filtered;
  }

  /**
   * Get all Challenge Prompts.
   *
   * @returns A list of all Challenge Prompts.
   */
  async getChallengePrompts(): Promise<Prompt[]> {
    const prompts: Prompt[] = await this.getAllDocs<Prompt>("prompts");
    const filtered: Prompt[] = prompts.filter((prompt) => prompt.Type == "challenge");
    return filtered;
  }

}
