import { Prompt } from "@models/prompt";
import { FirebaseService } from "./firebase-service";


/**
 * Replace placeholders in prompts with pre-defined information.
 *
 * @param prompt The unformatted prompt.
 * @param toReplace The keyword to search for.
 * @param value The value to replace with.
 * @returns A formatted prompt task.
 */
function replacePlaceholder(prompt: string, toReplace:string, value: string | number): string {
  const namePlaceholderRegex = new RegExp(`{${toReplace}}`, "g");
  const replacedPrompt = prompt.replace(namePlaceholderRegex, value.toString());
  return replacedPrompt;
}

/**
 * Build the Bevved questions and format ready for play.
 *
 * @param names The players names to inject.
 * @returns A formatted list of Prompts.
 */
export async function buildBevved(names: string[]): Promise<Prompt[]> {
  const firebase = new FirebaseService();
  const unformatted: Prompt[] = await firebase.getPrompts();
  const formatted: Prompt[] = [];
  for (const prompt of unformatted) {
    prompt.Task = replacePlaceholder(prompt.Task, "name", names[Math.floor(Math.random() * names.length)]);
    prompt.Task = replacePlaceholder(prompt.Task, "sip_number", Math.floor(Math.random() * 10) + 1);
    formatted.push(prompt);
  }
  return formatted;
}
