import React, { useContext } from "react";
import Header from "../components/header";
import { GameConfigContext } from "src/providers/game-provider";

export const SettingsView: React.FC = () => {

  // Access the game configuration and setter functions from the context
  const { gameConfig, setters } = useContext(GameConfigContext)!;
  const { nsfw } = gameConfig;
  const { setNsfw } = setters;

  const handleNsfwToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNsfw(event.target.checked);
  };


  return (
    <div className="h-dvh w-screen bg-gray-900 flex flex-col justify-start items-center">
      <Header backNavLocation="/" title="settings"/>

      <div className="h-screen flex flex-col justify-center items-center">
        <label className="relative inline-flex items-center mb-5 cursor-pointer">
          <input type="checkbox" checked={nsfw} onChange={handleNsfwToggle} className="sr-only peer"/>
          <div className="relative w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4  dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          <div className="block">
            <span className="ml-3 text-sm font-normal text-gray-600 mb-1">NSFW?</span>
            <br/>
            <span className="ml-3 text-sm font-normal text-gray-400 ">Filters NSFW questions and prompts</span>
          </div>
        </label>
      </div>

      <div className="
        w-screen flex flex-row justify-center items-center
        h-10
      ">
        <span className="
          text-gray-400 text-sm
        ">
          Created by <a className="underline" href="https://www.davidkidd.dev">David Kidd</a>
        </span>
      </div>

    </div>
  );
};
